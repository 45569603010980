<template>
  <v-row>
    <v-col>
      <!-- Filter By Doctor -->
      <div class="row justify-content-end align-items-center mb-2">
        <div class="col-md-3 co-12">
          <treeselect
            id="input-doctor"
            v-model="filter.doctor_id"
            :multiple="false"
            :options="doctors"
            placeholder="Filter Berdasar Dokter"
            @select="filterDoctorOnSelect"
          />
        </div>
      </div>
      <!-- Calendar -->
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          :now="today"
          :value="today"
          :events="events"
          :event-color="getEventColor"
          color="primary"
          type="week"
          locale="id"
          @click:event="showEvent"
          @change="getEvents"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <!-- <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-toolbar-title
                v-html="`Praktek ${selectedEvent.name}`"
              ></v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-toolbar>
            <v-card-text>
              <span
                v-html="
                  `<p style='font-size: 13px'>${selectedEvent.details}</p>`
                "
              ></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import DoctorModule from "@/core/modules/DoctorModule";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
  },

  data: () => ({
    filter: {
      doctor_id: "",
    },
    doctors: [{ label: "Pilih Dokter", id: "", isDisabled: true }],
    today: new Date().toISOString().substr(0, 10),
    events: [],
    colors: [
      "red",
      "red lighten-5",
      "red lighten-4",
      "red lighten-3",
      "red lighten-2",
      "red lighten-1",
      "red darken-1",
      "red darken-2",
      "red darken-3",
      "red darken-4",
      "red accent-1",
      "red accent-2",
      "red accent-3",
      "red accent-4",
      "pink",
      "pink lighten-5",
      "pink lighten-4",
      "pink lighten-3",
      "pink lighten-2",
      "pink lighten-1",
      "pink darken-1",
      "pink darken-2",
      "pink darken-3",
      "pink darken-4",
      "pink accent-1",
      "pink accent-2",
      "pink accent-3",
      "pink accent-4",
      "purple",
      "purple lighten-5",
      "purple lighten-4",
      "purple lighten-3",
      "purple lighten-2",
      "purple lighten-1",
      "purple darken-1",
      "purple darken-2",
      "purple darken-3",
      "purple darken-4",
      "purple accent-1",
      "purple accent-2",
      "purple accent-3",
      "purple accent-4",
      "deep-purple",
      "deep-purple lighten-5",
      "deep-purple lighten-4",
      "deep-purple lighten-3",
      "deep-purple lighten-2",
      "deep-purple lighten-1",
      "deep-purple darken-1",
      "deep-purple darken-2",
      "deep-purple darken-3",
      "deep-purple darken-4",
      "deep-purple accent-1",
      "deep-purple accent-2",
      "deep-purple accent-3",
      "deep-purple accent-4",
      "indigo",
      "indigo lighten-5",
      "indigo lighten-4",
      "indigo lighten-3",
      "indigo lighten-2",
      "indigo lighten-1",
      "indigo darken-1",
      "indigo darken-2",
      "indigo darken-3",
      "indigo darken-4",
      "indigo accent-1",
      "indigo accent-2",
      "indigo accent-3",
      "indigo accent-4",
      "blue",
      "blue lighten-5",
      "blue lighten-4",
      "blue lighten-3",
      "blue lighten-2",
      "blue lighten-1",
      "blue darken-1",
      "blue darken-2",
      "blue darken-3",
      "blue darken-4",
      "blue accent-1",
      "blue accent-2",
      "blue accent-3",
      "blue accent-4",
      "light-blue",
      "light-blue lighten-5",
      "light-blue lighten-4",
      "light-blue lighten-3",
      "light-blue lighten-2",
      "light-blue lighten-1",
      "light-blue darken-1",
      "light-blue darken-2",
      "light-blue darken-3",
      "light-blue darken-4",
      "light-blue accent-1",
      "light-blue accent-2",
      "light-blue accent-3",
      "light-blue accent-4",
      "cyan",
      "cyan lighten-5",
      "cyan lighten-4",
      "cyan lighten-3",
      "cyan lighten-2",
      "cyan lighten-1",
      "cyan darken-1",
      "cyan darken-2",
      "cyan darken-3",
      "cyan darken-4",
      "cyan accent-1",
      "cyan accent-2",
      "cyan accent-3",
      "cyan accent-4",
      "teal",
      "teal lighten-5",
      "teal lighten-4",
      "teal lighten-3",
      "teal lighten-2",
      "teal lighten-1",
      "teal darken-1",
      "teal darken-2",
      "teal darken-3",
      "teal darken-4",
      "teal accent-1",
      "teal accent-2",
      "teal accent-3",
      "teal accent-4",
      "green",
      "green lighten-5",
      "green lighten-4",
      "green lighten-3",
      "green lighten-2",
      "green lighten-1",
      "green darken-1",
      "green darken-2",
      "green darken-3",
      "green darken-4",
      "green accent-1",
      "green accent-2",
      "green accent-3",
      "green accent-4",
      "light-green",
      "light-green lighten-5",
      "light-green lighten-4",
      "light-green lighten-3",
      "light-green lighten-2",
      "light-green lighten-1",
      "light-green darken-1",
      "light-green darken-2",
      "light-green darken-3",
      "light-green darken-4",
      "light-green accent-1",
      "light-green accent-2",
      "light-green accent-3",
      "light-green accent-4",
    ],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),

  methods: {
    async getDoctors() {
      const data = await DoctorModule.getTreeSelect();
      this.doctors.push(...data);
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        let dayName = new Date(event.start).toLocaleDateString("id-ID", {
          weekday: "long",
        });
        let start = new Date(event.start).toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        });
        let end = new Date(event.end).toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        });
        this.selectedEvent.details = `Praktek ${event.name} Pada ${dayName} ${start} s/d ${end}`;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 30);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 30);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    getEventColor(event) {
      return event.color;
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    async getAppointment() {
      return await module.list(
        `schedules/get-calendar?doctor_id=${this.filter.doctor_id}`
      );
    },

    async filterDoctorOnSelect(evt) {
      await this.filter.doctor_id;
      this.getEvents();
    },

    async getEvents() {
      let arr = [],
        date = this.getMonday(new Date());
      for (let i = 1; i <= 8; i++) {
        i == 1
          ? arr.push(new Date(date.setDate(date.getDate())))
          : arr.push(new Date(date.setDate(date.getDate() + 1)));
      }
      const events = [];
      const schedules = await this.getAppointment(this.filter.doctor_id);
      let start, end;
      let usedColors = []; // Array untuk menyimpan warna yang sudah dipakai

      for (let i = 0; i < schedules.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (
            schedules[i].day_name ==
            new Date(arr[j]).toLocaleString("id-ID", { weekday: "long" })
          ) {
            start = new Date(arr[j]);
            start.setHours(schedules[i].start_hour);
            start.setMinutes(schedules[i].start_minute);
            end = new Date(arr[j]);
            end.setHours(schedules[i].end_hour);
            end.setMinutes(schedules[i].end_minute);
            let day_number = start.getDay();
            if (day_number == 0) {
              // Kurangi 6 hari
              start.setDate(start.getDate() - 7);
              end.setDate(end.getDate() - 7);
            }

            // Cari warna yang belum digunakan
            let availableColors = this.colors.filter(
              (color) => !usedColors.includes(color)
            );
            if (availableColors.length === 0) {
              // Jika semua warna sudah terpakai, reset daftar warna yang digunakan
              usedColors = [];
              availableColors = this.colors.slice();
            }

            // Pilih warna acak dari daftar warna yang tersedia
            let randomIndex = this.random(0, availableColors.length - 1);
            let selectedColor = availableColors[randomIndex];
            usedColors.push(selectedColor); // Tandai warna sebagai sudah dipakai

            events.push({
              name: schedules[i].name,
              weekdays: 1,
              start: start,
              end: end,
              color: selectedColor,
              timed: true,
            });
          }
        }
      }
      this.events = events;
    },

    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterDoctorOnSelect();
      }
    },
  },

  mounted() {
    this.$refs.calendar.scrollToTime("08:00");
    this.getDoctors();
  },
};
</script>

<style>
.bg-filed {
  background-color: #007bff !important;
}

.bg-conf-wait {
  background-color: #ffc107 !important;
  color: black !important;
}

.bg-conf {
  background-color: #28a745 !important;
}

.bg-handled {
  background-color: #17a2b8 !important;
}
</style>